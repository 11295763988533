<template>
    <a-modal
      :visible="visible"
      :title="model.readOnly ? 'Lihat Wilayah' : form.currentId ? 'Edit Wilayah' : 'Tambah Wilayah'"
      @ok="handleOk"
      @cancel="handleCancel"
      width="1000px"
      :destroy-on-close="true"
    >
      <template #footer>
        <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          :hidden="model.readOnly"
          @click="handleOk"
          >Submit</a-button
        >
      </template>
      <a-form
        ref="formRef"
        class="myform"
        :model="form"
        :wrapper-col="{ span: 14 }"
        :scroll-to-first-error="true"
      >
        <a-input
          width="100%"
          v-model:value="currentId"
          :disabled="model.readOnly"
          :hidden="true"
        />
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="ID"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              name="id"
              has-feedback
            >
              <a-input
                placeholder="ID"
                class="w-100"
                v-model:value="id"
                :disabled="model.readOnly || form.currentId != null ? true : false"
                @keydown="checkKeyDownNumeric($event)"
              />
              <div v-if="validation.id" class="text-danger my-n2">
                {{ validation.id[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Nama"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              has-feedback
              name="name"
            >
              <a-input
                placeholder="Nama Wilayah"
                class="w-100"
                v-model:value="name"
                :disabled="model.readOnly"
              />
              <div v-if="validation.name" class="text-danger my-n2">
                {{ validation.name[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Level"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              name="level"
              has-feedback
            >
              <a-select
                class="w-100"
                :options="[
                  { value: 1, label: 'Level 1 - Provinsi' },
                  { value: 2, label: 'Level 2 - Kabupaten' },
                  { value: 3, label: 'Level 3 - Kecamatan' },
                  { value: 4, label: 'Level 4 - Desa' },
                ]"
                placeholder="Pilih Level"
                v-model:value="level"
                :disabled="model.readOnly"
              />
              <div v-if="validation.level" class="text-danger my-n2">
                {{ validation.level[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Parent"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              name="parent_id"
              has-feedback
            >
              <FilterParent
                class="w-100"
                v-model:value="parent_id"
                v-model:level="level"
                placeholder="Pilih Parent"
                :mode="null"
                :disabled="model.readOnly || level === 1 ? true : false"
                all
                @keydown="checkKeyDownAlpha($event)"
              />
              <div v-if="validation.parent_id" class="text-danger my-n2">
                {{ validation.parent_id[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Area"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              name="area_id"
            >
              <filter-area
                placeholder="Area Wilayah"
                class="w-100"
                v-model:value="area_id"
                :disabled="model.readOnly"
                :mode="null"
              />
              <div v-if="validation.area_id" class="text-danger my-n2">
                {{ validation.area_id[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
  
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Kode pos"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              has-feedback
              name="poscode"
            >
              <a-input
                placeholder="Kode pos"
                type="text"
                width="100%"
                v-model:value="form.poscode"
                :disabled="model.readOnly"
                @keydown="checkKeyDownNumeric($event)"
              />
              <div v-if="validation.poscode" class="text-danger my-n2">
                {{ validation.poscode[0] }}
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-alert
        v-if="errorMessage"
        type="error"
        :message="errorMessage"
        banner
        closable
        @close="errorMessage = null"
      />
    </a-modal>
  </template>
  <script>
  import {
    defineComponent,
    reactive,
    toRef,
    toRefs,
    ref,
    computed,
    onBeforeMount,
    onMounted,
    watch,
  } from 'vue'
  import apiClient from '@/services/axios'
  import { message } from 'ant-design-vue'
  import { serialize } from 'object-to-formdata'
  import FilterParent from '@/components/filter/FilterParent'
  import FilterArea from '@/components/filter/FilterArea'
  
  export default defineComponent({
    components: {
      FilterParent,
      FilterArea,
    },
    props: {
      visible: [Boolean],
      model: {
        type: [Object],
        default: () => ({
          currentId: null,
          id: null,
          name: null,
          level: null,
          parent_id: null,
          area_id: null,
          poscode: null,
          record: {},
        }),
      },
    },
    emits: ['handleOk', 'update:visible'],
    setup(props, { emit }) {
      const form = reactive(Object.assign({}, props.model.record))
      const formRef = ref()
      const errorMessage = ref(null)
      const validation = ref([])
      const fetchData = () => {
        if (form.id) {
          apiClient.get('/api/wilayah/' + form.id).then(response => {
            formRef.value = response.data.result
            form.currentId = response.data.result.id
            form.id = response.data.result.id
            form.name = response.data.result.name
            form.level = response.data.result.level
            form.parent_id = response.data.result.parent_id
            form.area_id = response.data.result.area_id
            form.poscode = response.data.result.poscode
          })
        }
      }
  
      const handleOk = async () => {
        // try {
        //   await formRef.value.validate()
        // } catch (error) {
        //   return
        // }
  
        const form_data = serialize(
          {
            ..._.omit(form, ['reactive', 'state']),
          },
          { indices: true },
        )
  
        if (form.currentId) {
          const url = `/api/wilayah${form.id ? '/' + form.id + '?_method=PUT' : ''}`
          apiClient
            .post(url, form_data)
            .then(response => {
              if (response === undefined) {
                errorMessage.value = `Kode error 500, No response from server`
                return
              }
              message.success('Berhasil menyimpan')
              emit('handleOk', form, true)
            })
            .catch(async error => {
              message.error('Gagal menyimpan')
              errorMessage.value = null
              if (error.response.status == 500) {
                message.error('Gagal menyimpan')
                errorMessage.value = null
                validation.value = null
                if (error.response) {
                  const { status, statusText } = error.response
                  const message = error.response.data.message
                  errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                }
              } else {
                validation.value = error.response.data.errors
              }
            })
        } else {
          const postUrl = 'api/wilayah'
  
          if (form.parent_id == null) {
            form.parent_id = null
          } else if (form.parent_id.length == 0) {
            form.parent_id = null
          } else {
            form.parent_id = form.parent_id
          }
  
          console.log(form)
          apiClient
            .post(postUrl, form)
            .then(response => {
              message.success('Berhasil menyimpan')
              emit('handleOk', form, true)
            })
            .catch(async err => {
              if (err.response.status == 500) {
                message.error('Gagal menyimpan')
                errorMessage.value = null
                validation.value = null
                if (err.response) {
                  const { status, statusText } = err.response
                  const message = err.response.data.message
                  errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                }
              } else {
                validation.value = err.response.data.errors
              }
            })
        }
      }
  
      const handleCancel = e => {
        emit('update:visible', false)
      }
  
      watch(() => {})
  
      onBeforeMount(() => {
        fetchData()
      })
  
      return {
        form,
        ...toRefs(form),
        emit,
        handleOk,
        handleCancel,
        formRef,
        errorMessage,
        validation,
      }
    },
    methods: {
      checkKeyDownAlpha(event) {
        if (!/[a-zA-Z\s]/.test(event.key)) {
          if (event.key === "Backspace" || event.key === 'Delete') {
            //
          } else {
            event.preventDefault();
          }
        }
      },
      checkKeyDownNumeric(event) {
        if (!/[0-9\s]/.test(event.key)) {
          if (event.key === "Backspace" || event.key === 'Delete') {
            //
          } else {
            event.preventDefault();
          }
        }
      },
    },
  })
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/scss/form.scss';
  </style>
  